import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { Helmet } from 'react-helmet';

const Category = () => {
  const { id } = useParams();
  const [properties, setProperties] = useState([]);
  const [categoryName, setCategoryName] = useState('');
  const [seoData, setSeoData] = useState({
    page_title: '',
    meta_desc: '',
    keywords: '',
  });

  useEffect(() => {
    fetchCategoryName(id);
    fetchProperties(id);
  }, [id]);

  const fetchCategoryName = async (categoryId) => {
    try {
      const response = await fetch(`https://dayoffice24.it/backend/api/category-name/${categoryId}`);
      const data = await response.json();
      console.log('Fetched Category Data:', data);
      if (data && data.success && data.category_title) {
        setCategoryName(data.category_title);
        // Set Helmet SEO data based on API response
        setSeoData({
          page_title: data.seo_data.page_title,
          meta_desc: data.seo_data.meta_desc,
          keywords: data.seo_data.keywords,
        });
      } else {
        console.error('Error fetching category name:', data);
        setCategoryName(''); // Set blank category name when no category name is found
      }
    } catch (error) {
      console.error('Error fetching category name:', error);
      setCategoryName(''); // Set blank category name when there's an error
    }
  };

  const fetchProperties = async (categoryId) => {
    try {
      const response = await fetch(`https://dayoffice24.it/backend/api/property/category-id/${categoryId}`);
      const data = await response.json();
      console.log('Fetched Data:', data);
      if (data && data.success && data.data) {
        setProperties(data.data);
      } else {
        console.error('Error fetching property data:', data);
        setProperties([]); // Set empty array when no properties are found
      }
    } catch (error) {
      console.error('Error fetching property data:', error);
      setProperties([]); // Set empty array when there's an error
    }
  };

  return (
    <div className="main_wrap">
      <Helmet>
        {/* Set Helmet meta tags based on API response */}
        <title>{seoData.page_title ? `${seoData.page_title} - HRD Group Srl S.` : 'Category - HRD Group Srl S.'}</title>
        <meta name="description" content={seoData.meta_desc} />
        <meta name="keywords" content={seoData.keywords} />
      </Helmet>

      <Header />
      <div className="content-wrapper">
        <div className="breadcrumb-wrap bg-f br-2">
          <div className="container">
            <div className="breadcrumb-title text-center">
              <h2>{categoryName || 'Category'}</h2>
              <ul className="breadcrumb-menu list-style">
                <li><Link to="/">Home</Link></li>
                {categoryName && <li>{categoryName}</li>}
              </ul>
            </div>
          </div>
        </div>
        <section className="listing-wrap ptb-100">
          <div className="container">
            {properties.length === 0 ? (
              <div className="text-center">
                <p className="no_property_name">Nessuna proprietà in questa categoria</p>
              </div>
            ) : (
              <div className="row">
                {properties.map((property) => (
                  <div className="col-xl-4 col-lg-6 col-md-6" key={property._id}>
                    <div className="property-card style4">
                      <div className="property-img">
                        <Link to={`/PropertyView/${property._id}`}><img src={property.thumb_image} alt={property.property_name} /></Link>
                        <span className="property-status">{property.property_type}</span>
                      </div>
                      <div className="property-info">
                        <h3>
                          <Link to={`/PropertyView/${property._id}`}>{property.property_name}</Link>
                        </h3>
                        <p>{property.availableDates.view_date}</p>
                        <p>
                          <i className="flaticon-location"></i>
                          {property.address}
                        </p>
                        {/* Render other property information */}
                        <ul className="property-metainfo list-style">
                          <li>
                            <i className="flaticon-user-avatar"></i>
                            {property.no_of_guest}
                          </li>
                          <li>
                            <i className="flaticon-square"></i>
                            {property.property_size} sqft
                          </li>
                        </ul>
                        <p className="property-price">
                          €{property.price}/<span>{property.price_charge_type}</span>
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default Category;
